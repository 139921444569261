import { Button, SelectField, TableCell, TableRow, Text, TextField } from '@aws-amplify/ui-react';
import { MdCancel, MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

import { DateTime } from 'luxon';

export enum EnumOp {
    new,
    edit,
    show
}

interface IProps {
    activity: codegenapi.Activity,
    activityTypes: codegenapi.ActivityType[],
    cancelEditing: () => any,
    date: number,
    deleteActivity: ((activitytypeid: string) => any) | undefined,
    editingActivityType: string | undefined,
    editingReferenceDataActivityType: string | undefined,
    gqlClient: IGQLClient
    op: EnumOp,
    setActivity: React.Dispatch<React.SetStateAction<codegenapi.Activity | undefined>>;
    setEditingActivityType: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEditingRefDataActivity: React.Dispatch<React.SetStateAction<codegenapi.Activity | undefined>>;
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const ActivityRow = (props: IProps): JSX.Element => {

    const [readonly, setReadonly] = useState<boolean>(props.op === EnumOp.show);
    const [localActivity, setLocalActivity] = useState<codegenapi.Activity>(props.activity);

    const { setActivity } = props;

    // Handle startTime and stopTime separately due to the epoch/UTC conversions
    const [startTime, setStartTime] = useState<string>('');
    const [stopTime, setStopTime] = useState<string>('');

    // Conversion function to convert local time to UTC epoch time
    const localtimeToEpochUTC = useCallback((localTime: string): number => {
        const osloTimeZone = 'Europe/Oslo';
        const dateTimeOslo = DateTime.fromFormat(localTime, 'yyyy-MM-dd HH:mm', { zone: osloTimeZone });
        const dateTimeUTC = dateTimeOslo.toUTC();
        return Math.floor(dateTimeUTC.toSeconds());
    }, []);

    // Conversion function to convert UTC epoch time to local time
    function epochUTCToLocaltime(epoch: number | undefined | null): string {
        if (epoch == null || isNaN(epoch)) {
            return '';
        }
        const osloTimeZone = 'Europe/Oslo';
        const dateTimeUTC = DateTime.fromSeconds(epoch).toUTC();
        const dateTimeOslo = dateTimeUTC.setZone(osloTimeZone);
        return dateTimeOslo.toFormat('yyyy-MM-dd HH:mm');
    }

    // Handle changes to the input fields
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.name === 'startTime')
            setStartTime(e.target.value);
        else if (e.target.name === 'stopTime')
            setStopTime(e.target.value);
        else
            setActivity(
                prevState => {
                    if (prevState) {
                        return { ...prevState, [e.target.name]: e.target.value }
                    }
                    if (!prevState) return { ...localActivity, [e.target.name]: e.target.value }
                });
    }

    // Handle changes to the activity type selection
    function handleActivityTypeChange(e: ChangeEvent<HTMLSelectElement>) {
        setActivity(
            prevState => {
                if (prevState) return { ...prevState, [e.target.name]: e.target.value }
                if (!prevState) return { ...localActivity, [e.target.name]: e.target.value }
            });
    }

    // Update parent state with conversion of startTime
    useEffect(() => {
        if (startTime)
            setActivity(
                prevState => {
                    if (prevState)
                        return { ...prevState, "startTime": localtimeToEpochUTC(startTime) }
                }
            );
    }, [startTime, setActivity, localtimeToEpochUTC]);

    // Update parent state with conversion of stopTime
    useEffect(() => {
        if (stopTime)
            setActivity(
                prevState => {
                    if (prevState)
                        return { ...prevState, "stopTime": localtimeToEpochUTC(stopTime) }
                }
            );
    }, [stopTime, setActivity, localtimeToEpochUTC]);

    // Set read-only flag based on activitytypeid
    useEffect(() => {
        setReadonly(
            !(props.op === EnumOp.new)  // New
            && !(props.editingActivityType === localActivity.activitytypeid)    // Edit
        );
    }, [props.editingActivityType, localActivity.activitytypeid, props.op]);

    // Keep localActivity state in sync with parent
    useEffect(() => {
        setLocalActivity(props.activity);
    }, [props.activity]);

    // Initialize local startTime/stopTime state with localActivity
    useEffect(() => {
        const defaultDate = DateTime.fromSeconds(props.date).setZone('Europe/Oslo').toFormat('yyyy-MM-dd');
        setStartTime(localActivity.startTime ? epochUTCToLocaltime(localActivity.startTime) : `${defaultDate} 00:00`);
        setStopTime(localActivity.stopTime ? epochUTCToLocaltime(localActivity.stopTime) : `${defaultDate} 00:00`);
    }, [localActivity, props.date]);

    // Validate startTime and stopTime
    function validateTimes(): boolean {
        const start = DateTime.fromFormat(startTime, 'yyyy-MM-dd HH:mm', { zone: 'Europe/Oslo' });
        const stop = DateTime.fromFormat(stopTime, 'yyyy-MM-dd HH:mm', { zone: 'Europe/Oslo' });
        return start.isValid && stop.isValid && stop > start;
    }

    return (
        <TableRow
            style={{ backgroundColor: props.editingReferenceDataActivityType && props.editingReferenceDataActivityType === props.activity?.activitytypeid ? 'lightcyan' : 'white' }}
        >
            {
                readonly &&
                <>
                    <TableCell>
                        <Text
                            fontWeight='bold'
                        >
                            {props.activity.activitytype?.description}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text>{epochUTCToLocaltime(props.activity?.startTime)}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{epochUTCToLocaltime(props.activity?.stopTime)}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{props.activity?.comment}</Text>
                    </TableCell>
                    <TableCell>
                        <Button
                            onClick={() => props.setEditingRefDataActivity(props.activity)}
                        >
                            {props.activity?.referenceData ? props.activity?.referenceData.length : 0}
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => props.setEditingActivityType(props.activity.activitytype?.activitytypeid)}
                        >
                            <MdEdit />
                        </Button>
                        <Button
                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => props.deleteActivity && props.deleteActivity(props.activity.activitytypeid)}
                        >
                            <MdDelete />
                        </Button>
                    </TableCell>
                </>
            }
            {
                !readonly &&
                <>
                    <TableCell>
                        <SelectField
                            name='activitytypeid'
                            defaultValue={localActivity.activitytype?.activitytypeid}
                            label=''
                            onChange={handleActivityTypeChange}
                            placeholder='Select activity...'
                            disabled={props.op !== EnumOp.new}
                            labelHidden
                            style={{ width: '12rem' }}>
                            {
                                props.activityTypes &&
                                props.activityTypes.length > 0 &&
                                props.activityTypes.sort(
                                    (a: codegenapi.ActivityType, b: codegenapi.ActivityType) => a.description.localeCompare(b.description)
                                ).map((activityType: codegenapi.ActivityType) => (
                                    <option
                                        key={activityType.activitytypeid}
                                        value={activityType.activitytypeid}
                                    >
                                        {activityType.description}
                                    </option>
                                ))
                            }
                        </SelectField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='startTime'
                            value={startTime}
                            label=''
                            onChange={handleChange}
                            pattern="^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$"
                            variation='quiet'
                            required
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '12rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='stopTime'
                            value={stopTime}
                            label=''
                            onChange={handleChange}
                            pattern="^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$"
                            variation='quiet'
                            required
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '12rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='comment'
                            defaultValue={localActivity.comment ?? ''}
                            label=''
                            onChange={handleChange}
                            variation='quiet'
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '12rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='referenceData'
                            defaultValue={localActivity.referenceData ? localActivity.referenceData.length : 'none'}
                            label=''
                            onChange={handleChange}
                            variation='quiet'
                            disabled
                            labelHidden
                            style={{ width: '5rem' }} />
                    </TableCell>
                    <TableCell>
                        <Button type="submit" disabled={!validateTimes()}>
                            <MdCheck />
                        </Button>
                        <Button onClick={() => {
                            props.cancelEditing();
                        }}>
                            <MdCancel />
                        </Button>
                    </TableCell>
                </>
            }
        </TableRow>
    )
}

export default ActivityRow;