import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdOutlineAdd } from 'react-icons/md';

import ActivityRow, { EnumOp } from './activityrow';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';
import { DateTime } from 'luxon';

interface IProps {
    activities: codegenapi.Activity[] | undefined,
    activityTypes: codegenapi.ActivityType[] | undefined,
    date: number,
    gqlClient: IGQLClient,
    editingReferenceDataActivityType: string | undefined,
    setEditingRefDataActivity: React.Dispatch<React.SetStateAction<codegenapi.Activity | undefined>>,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const ActivitiesList = (props: IProps): JSX.Element => {

    const { subjectid } = useParams();

    const [unusedActivityTypes, setUnusedActivityTypes] = useState<codegenapi.ActivityType[]>();

    const [newActivity, setNewActivity] = useState<codegenapi.Activity>();

    const [updatedActivity, setUpdatedActivity] = useState<codegenapi.Activity>();

    const [editingActivityType, setEditingActivityType] = useState<string | undefined>();

    useEffect(() => {
        const activity = props.activities?.find(activity => activity.activitytypeid === editingActivityType);
        if (activity) {
            setUpdatedActivity({
                ...activity,
                startTime: activity.startTime,
                stopTime: activity.stopTime
            });
        }
    }, [props.activities, editingActivityType]);

    useEffect(() => {
        const usedActivityTypes: string[] = [];
        props.activities?.map(act => usedActivityTypes.push(act.activitytypeid));
        setUnusedActivityTypes(props.activityTypes?.filter(at => usedActivityTypes.indexOf(at.activitytypeid) === -1));
    }, [props.activities, props.activityTypes]);

    function cancelEditingActivity() {
        setEditingActivityType(undefined);
    }

    function cancelNewActivity() {
        setNewActivity(undefined);
    }

    function deleteActivity(activitytypeid: string) {
        if (subjectid)
            props.gqlClient.gqlRemoveActivity(subjectid, activitytypeid)
                .catch((errors) => {
                    console.error(errors);
                });
    }

    function postValidation(e: FormEvent<HTMLDivElement>) {
        e.preventDefault();

        if (updatedActivity) {
            console.log("Updated Activity:", updatedActivity);
            if (!updatedActivity.subjectid || !updatedActivity.activitytypeid || !updatedActivity.startTime || !updatedActivity.stopTime) {
                console.error("Missing required fields in updatedActivity");
                return;
            }
            props.gqlClient.gqlUpdateActivity(
                updatedActivity.subjectid,
                updatedActivity.activitytypeid,
                updatedActivity.startTime,
                updatedActivity.stopTime,
                updatedActivity.comment
            )
                .then(() => {
                    setEditingActivityType(undefined);
                })
                .catch((errors) => {
                    console.error(errors);
                });
        }

        if (newActivity && subjectid) {
            console.log("New Activity:", newActivity);
            props.gqlClient.gqlAddActivity(
                subjectid,
                newActivity.activitytypeid,
                newActivity.startTime,
                newActivity.stopTime,
                newActivity.comment
            )
                .then(() => {
                    setNewActivity(undefined);
                })
                .catch((errors) => {
                    console.error(errors);
                });
        }
    }

    return (
        <Flex as="form" onSubmit={postValidation}>
            <Loader variation="linear" style={{ display: props.activities && 'none' }} />
            {
                props.activities &&
                <Flex>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Type</TableCell>
                                <TableCell as="th">Start</TableCell>
                                <TableCell as="th">Stop</TableCell>
                                <TableCell as="th">Comment</TableCell>
                                <TableCell as="th">Reference data</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.activities.length > 0 &&
                                props.activities
                                    .sort((a, b) => !a.activitytype ? 0 : a.activitytype.description.localeCompare(b.activitytype?.description ?? ''))
                                    .map(
                                        (activity) => (
                                            <ActivityRow
                                                activity={activity}
                                                activityTypes={props.activityTypes ?? []}
                                                cancelEditing={cancelEditingActivity}
                                                date={props.date}
                                                deleteActivity={deleteActivity}
                                                editingActivityType={editingActivityType}
                                                editingReferenceDataActivityType={props.editingReferenceDataActivityType}
                                                gqlClient={props.gqlClient}
                                                key={activity.activitytypeid}
                                                op={EnumOp.show}
                                                setActivity={setUpdatedActivity}
                                                setEditingActivityType={setEditingActivityType}
                                                setEditingRefDataActivity={props.setEditingRefDataActivity}
                                                siteid={props.siteid}
                                                subject={props.subject}
                                            />
                                        )
                                    )
                            }
                            {
                                !newActivity && props.activities.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={5}><i>No activities yet!</i></TableCell>
                                </TableRow>
                            }
                            {
                                newActivity &&
                                <ActivityRow
                                    activity={newActivity}
                                    activityTypes={unusedActivityTypes ?? []}
                                    cancelEditing={cancelNewActivity}
                                    date={props.date}
                                    deleteActivity={undefined}
                                    editingActivityType={editingActivityType}
                                    editingReferenceDataActivityType={props.editingReferenceDataActivityType}
                                    key='newActivity'
                                    gqlClient={props.gqlClient}
                                    op={EnumOp.new}
                                    setEditingActivityType={setEditingActivityType}
                                    setEditingRefDataActivity={props.setEditingRefDataActivity}
                                    setActivity={setNewActivity}
                                    siteid={props.siteid}
                                    subject={props.subject}
                                />
                            }
                            {
                                !newActivity && !editingActivityType &&
                                <TableRow key='new'>
                                    <TableCell colSpan={6}>
                                        <Button
                                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            onClick={() => {
                                                const defaultDate = DateTime.fromSeconds(props.date).setZone('Europe/Oslo').toFormat('yyyy-MM-dd HH:mm');
                                                setNewActivity({
                                                    __typename: 'Activity',
                                                    subjectid: subjectid!,
                                                    activitytypeid: '',
                                                    startTime: DateTime.fromFormat(defaultDate, 'yyyy-MM-dd HH:mm', { zone: 'Europe/Oslo' }).toSeconds(),
                                                    stopTime: DateTime.fromFormat(defaultDate, 'yyyy-MM-dd HH:mm', { zone: 'Europe/Oslo' }).toSeconds(),
                                                    comment: '',
                                                    referenceData: []
                                                } as codegenapi.Activity);
                                            }}
                                            variation='primary'
                                        >
                                            <MdOutlineAdd />
                                        </Button>
                                    </TableCell>
                                </TableRow>

                            }
                        </TableBody>
                    </Table>
                </Flex>
            }
        </Flex>
    )
}

export default ActivitiesList;